import React, { useState, useEffect } from 'react'
import { history } from 'router'
import { useSelector, useDispatch } from 'react-redux'
import { notification, Select, Radio, Divider } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { Counter, CustomButton, ModalBuyTicket, OverflowNotes } from 'components'
import { getCurrencySymbolByCode, isOnDemand, getFormattedNumber, checkEarlyBirdActuality } from 'utils'
import {
  dereserveSeats,
  guestDereserveSeats,
} from 'modules/events/events-actions'
import {
  removeBuySeat,
} from 'modules/templates/templates-actions'
import moment from 'moment-timezone'
import seatsIMG from 'images/chooseSeats.svg'
import otherIMG from 'images/otherTicket.svg'
import bundlesIMG from 'images/bundles.svg'
import NoEventsInformation from 'images/NoEventsInformation.svg'
import RemoveBuySeat from 'images/removeBuySeat.svg'
import './index.styl'
import isInteger from 'lodash/isInteger'
import ChooseSeats from '../ChooseSeats'
import ModalRaffleCategory from '../../../components/ModalRaffleCategory'

const { Option } = Select

// const bundlesArray = [
//   {
//     id: 1,
//     categoryName: 'PPV',
//     quntity: 15,
//     price: 10,
//     ticketCount: {
//       avaliable: 15,
//       total: 15,
//     },
//   },
//   {
//     id: 2,
//     categoryName: 'PPV',
//     quntity: 200,
//     price: 10,
//     ticketCount: {
//       avaliable: 200,
//       total: 200,
//     },
//   },
//   {
//     id: 4,
//     categoryName: 'Cate3',
//     quntity: 200,
//     price: 12,
//     ticketCount: {
//       avaliable: 200,
//       total: 200,
//     },
//   },
// ]
const Body = ({ getEventData }) => {
  const {
    tickets = [],
    bundles = [],
    imageUrl,
    assignedSeatings,
    reservedSeatsArray = [],
    isDonationEnable,
    id,
    currency,
    endDate,
    description,
    virtualEvent,
    showAvailableTickets = false,
    isEventCancelled,
    subpromoters,
    inviterID,
    userID,
    timeZone,
  } = useSelector(state => state.events.currentEvent)
  const onDemand = isOnDemand(endDate)
  const [visible, setVisible] = useState(false)
  const [allowBuyTicket, setAllowBuyTicket] = useState(false)
  const [availableTickets] = useState(
    tickets.map(i => ({
      count: 0,
      total: 0,
      price: i.price,
      isEarlyBird: i.isEarlyBird,
      earlyBirdPrice: i.earlyBirdPrice,
      id: i._id,
      categoryName: i.categoryName,
      isStreaming: i.isStreaming,
      isRaffleCategory: i.isRaffleCategory,
      earlyBirdDate: i.earlyBirdDate,
    })))
  const filterTickets = _tickets => _tickets.filter(t => {
    return !t.isSeatingCategory && !(onDemand && !t.isStreaming) && (!t.isDonate)
  })
  const rightPromoterName = subpromoters => {
    return subpromoters.some(subP => subP.id === inviterID) ? `${subpromoters.find(sub => sub.id === inviterID).firstName}
    ${subpromoters.find(sub => sub.id === inviterID).lastName}` : 'No subpromoters'
  }
  const [selectedTickets, setSelectedTickets] = useState([])
  const [filteredTickets, setFilteredTickets] = useState(filterTickets(tickets))
  const showSeatsTab = !!assignedSeatings
  const showOtherTab = !!filteredTickets.length
  const showBundlesTab = !!bundles.length
  const [donationAmount, setDonationAmount] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [totalPriceSeats, setTotalPriceSeats] = useState(0)
  const [totalPriceTickets, setTotalPriceTickets] = useState(0)
  const [totalPriceBundles, setTotalPriceBundles] = useState(0)
  const [selectValue, setSelectValue] = useState(rightPromoterName(subpromoters))
  const [buySeat, setBuySeat] = useState(showSeatsTab ? 'seats' : showOtherTab ? 'other' : 'bundles')
  const [selectedSeat, setSelectedSeat] = useState([])
  const [selectedBundles, setSelectedBundles] = useState([])
  const [visibleModalRaffle, setVisibleModalRaffle] = useState(false)
  const raffleCategory = filteredTickets.find(ticket => ticket.isRaffleCategory)
  const indexRaffleCategory = filteredTickets.findIndex(ticket => ticket.isRaffleCategory)
  const { user } = useSelector(state => state.session)
  const isDonationEnableLocal = (inviterID !== userID) ? false : isDonationEnable
  const dispatch = useDispatch()
  const updateAfterBuy = () => {
    getEventData()
    setSelectedTickets([])
    setSelectedSeat([])
    setDonationAmount(0)
  }
  useEffect(() => {
    let sumTicket = 0
    let sumSeat = 0
    let sumBundles = 0
    selectedTickets.forEach(i => (sumTicket += i.total))
    selectedSeat.forEach(i => (sumSeat += i.price))
    selectedBundles.forEach(i => (sumBundles += i.total))
    setTotalPriceSeats(sumSeat)
    setTotalPriceTickets(sumTicket)
    setTotalPriceBundles(sumBundles)
    setTotalPrice(sumSeat + sumTicket + sumBundles)
  }, [selectedTickets, donationAmount, selectedSeat, selectedBundles])
  useEffect(() => {
    setFilteredTickets(filterTickets(tickets))
  }, [tickets])
  useEffect(() => {
    if (reservedSeatsArray && user) {
      const arr = reservedSeatsArray
        .filter(i => i.reservedBy === user?._id)
        .map(i => {
          const isEarlyBird = i.category.isEarlyBird && checkEarlyBirdActuality(i.category.earlyBirdDate, timeZone)

          const currentPrice = isEarlyBird ? i.category.earlyBirdPrice : i.price
          return {
            seatId: i.id,
            sectionName: i.sectionName,
            rowName: i.rowName,
            seatName: i.seatName,
            sectionType: i.sectionType,
            seatCategoryID: i.category.ticketCategoryID,
            price: currentPrice,
            priceWithoutEarlyBird: i.price,
            isActive: i.isActive,
            isEarlyBird: i.category.isEarlyBird,
            earlyBirdPrice: i.category.earlyBirdPrice,
            earlyBirdDate: i.category.earlyBirdDate,
          }
        })
      setSelectedSeat([...selectedSeat, ...arr])
    }
  }, [])

  const onChange = () => {
    if (allowBuyTicket) {
      notification.warning({
        message: "You can't buy more tickets than available",
      })
    } else {
      if (totalPrice || donationAmount) {
        const hasRaffleTicket = trimArray(selectedTickets)?.some(ticket => ticket.isRaffleCategory)
        if ((!hasRaffleTicket && raffleCategory && raffleCategory?.ticketCount.available !== 0)) {
          setVisibleModalRaffle(true)
        } else {
          setVisible(!visible)
        }
      } else {
        notification.warning({
          message: 'Please enter quantity greater than 0',
        })
      }
    }
  }

  const getFixed = num => num % 1 === 0 ? num : num.toFixed(2)
  const currencySymbol = getCurrencySymbolByCode(currency)
  const getPriceDisplay = ind => {
    return filterTickets(tickets)[ind].isEarlyBird && moment.tz(timeZone) <= moment.tz(filterTickets(tickets)[ind].earlyBirdDate, timeZone)
      ? <><span>{currencySymbol}{getFixed(filterTickets(tickets)[ind].earlyBirdPrice)}</span>&nbsp;<span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px', marginRight: '12px' }}>{currencySymbol}{getFixed(filterTickets(tickets)[ind].price)}</span></>
      : <span>{currencySymbol}{getFixed(filterTickets(tickets)[ind].price)}</span>
  }
  const setSelected = (ticketID, num, i) => {
    if (isNaN(num) || num < 0) num = 0
    if (!isInteger(num)) num = Math.trunc(num)
    const selectedTicketIndex = availableTickets.findIndex(cat => cat.id === ticketID)
    const currentAvailableTicket = availableTickets.find(cat => cat.id === ticketID)
    selectedTickets[selectedTicketIndex] = currentAvailableTicket
    if (!i.isDonate) {
      if (num >= i?.ticketCount.available) {
        num = i?.ticketCount.available
      }
      const newSelectedTickets = selectedTickets.map(i => {
        const selectedPrice = i?.isEarlyBird && checkEarlyBirdActuality(i?.earlyBirdDate, timeZone) ? i?.earlyBirdPrice : i?.price
        return i.id === ticketID
          ? { ...i, price: i.price, count: num, total: num * selectedPrice }
          : { ...i, price: i.price }
      })
      setSelectedTickets(newSelectedTickets)
    } else if (i.isDonate) {
      const newSelectedTickets = selectedTickets.map(i =>
        i.id === ticketID ? { ...i, price: i.price, total: num, count: 1, isDonate: true } : { ...i, price: i.price },
      )
      setSelectedTickets(newSelectedTickets)
    }
  }
  const handleSelectBundles = (index, num, bundle) => {
    if (isNaN(num) || num < 0) num = 0
    if (!isInteger(num)) num = Math.trunc(num)
    selectedBundles[index] = bundles[index]
    if (!bundle.isDonate) {
      if (num >= bundle?.bundleCount.available) {
        num = bundle?.bundleCount.available
      }
      const newSelectedBundle = selectedBundles.map((i, ind) =>
        i.id === bundle.id ? { ...i, price: i.price, count: num, total: num * i.price } : { ...i, price: i.price },
      )
      setSelectedBundles(newSelectedBundle)
    } else if (bundle.isDonate) {
      const newSelectedBundle = selectedBundles.map((i, ind) =>
        i.id === bundle.id ? { ...i, price: i.price, total: num, count: 1, isDonate: true } : { ...i, price: i.price },
      )
      setSelectedBundles(newSelectedBundle)
    }
  }
  const setDonateSelected = num => {
    if (isNaN(num) || num < 0) num = 0
    setDonationAmount(num)
  }
  const trimArray = array => {
    return array.filter(item => item.total > 0 || item.count > 1)
  }
  const trimArraySeat = array => {
    return array.filter(item => item.isActive || item.isReserved)
  }
  const eventDescription = virtualEvent?.description || description || ''
  const handleSelect = sellerID => {
    setSelectValue(sellerID)
    history.push(`/buy/${id}/${sellerID}`)
  }
  const handleBuySeat = ({ target: { value } }) => {
    setBuySeat(value)
  }
  const handleRemoveSeat = (seatID, guestReservationToken) => {
    const removeById = selectedSeat.filter(obj => obj.seatId !== seatID)
    const obj = { eventID: id, seatID: seatID }
    const dereserveGuestObj = { eventID: id, seatID: seatID, guestReservationToken }
    if (user) {
      dispatch(dereserveSeats(obj)).then(() => {
      // dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seatID }))
        dispatch(removeBuySeat(seatID))
      })
    } else {
      dispatch(guestDereserveSeats(dereserveGuestObj)).then(() => {
      // dispatch(eventsUnreserveSeat({ sectionId: sectionID, groupId: group.id, seatId: seatID }))
        dispatch(removeBuySeat(seatID))
      })
    }
    return setSelectedSeat(removeById)
  }
  const handleRemoveCategory = id => {
    const updatedSelectedTickets = selectedTickets.map(ticket =>
      ticket.id === id ? { ...ticket, count: 0, total: 0 } : ticket,
    )
    setSelectedTickets(updatedSelectedTickets)
  }
  const handleRemoveBundle = id => {
    const updatedSelectedBundles = selectedBundles.map(bundle =>
      bundle.id === id ? { ...bundle, count: 0, total: 0 } : bundle,
    )
    setSelectedBundles(updatedSelectedBundles)
  }
  const onCloseRaffleModal = () => {
    setVisibleModalRaffle(false)
    setVisible(!visible)
  }

  const handleAddRaffleCategory = () => {
    if (raffleCategory) {
      const raffleTicket = {
        id: raffleCategory._id,
        categoryName: raffleCategory.categoryName,
        price: raffleCategory.price,
        count: 1,
        isEarlyBird: raffleCategory.isEarlyBird,
        earlyBirdPrice: raffleCategory.earlyBirdPrice,
        earlyBirdDate: raffleCategory.earlyBirdDate,
        total: raffleCategory.price || 0,
        isRaffleCategory: true,
        isStreaming: false,
      }
      selectedTickets[indexRaffleCategory] = raffleTicket
      setSelectedTickets(selectedTickets)
      setTotalPrice(totalPrice + raffleCategory.price)
      setVisibleModalRaffle(false)
    }
  }
  const handleBack = () => history.push('/home')

  const donationAmountSumm = donationAmount && donationAmount.toLocaleString('en-US', { style: 'decimal', maximumFractionDigits: 2 })
  const numTrueValues = [showSeatsTab, showOtherTab, showBundlesTab].filter(Boolean).length
  const soldRaffleTicket = raffleCategory?.ticketCount?.total - raffleCategory?.ticketCount?.available
  const isInviteOnlyEvent = !showSeatsTab && !showOtherTab && !showOtherTab
  return (
    <div className='wrapperBodyEvent'>
      {isInviteOnlyEvent ? (
        <div className='wrapperBodyInviteOnly'>
          <img src={NoEventsInformation} alt='Information Logo' />
          <div className='informationDescription'>This is not a public event. Please contact the promoter for further details</div>
          <CustomButton text='Back' type='dark' onClick={handleBack} />
        </div>
      ) : (
        <>
          <div className='wrapperBodyTicket'>
            {eventDescription?.trim() && <OverflowNotes notes={eventDescription} />}
            {numTrueValues >= 2 && (
              <div className='buySeatRadioWrapper' style={buySeat !== 'seats' ? { marginBottom: '37px' } : {}}>
                <Radio.Group onChange={handleBuySeat} value={buySeat} className='radioContainer' optionType='button'>
                  {showSeatsTab &&
                    <Radio className='radioItem' value='seats'>
                      <div className='radioItemContainer'>
                        <img src={seatsIMG} alt='' className='categoryImg' />
                        <div className='categoryLabel'>Tables</div>
                      </div>
                    </Radio>}
                  {showOtherTab &&
                    <Radio className='radioItem' value='other'>
                      <div className='radioItemContainer'>
                        <img src={otherIMG} alt='' className='categoryImg' />
                        <div className='categoryLabel'>{showSeatsTab ? 'Other Tickets' : 'Tickets'}</div>
                      </div>
                    </Radio>}
                  {showBundlesTab &&
                    <Radio className='radioItem' value='bundles'>
                      <div className='radioItemContainer'>
                        <img src={bundlesIMG} alt='' className='categoryImg' />
                        <div className='categoryLabel'>Bundles</div>
                      </div>
                    </Radio>}
                </Radio.Group>
              </div>)}
            {buySeat === 'seats' && showSeatsTab && !onDemand ? (
              <ChooseSeats timeZone={timeZone} isEventCancelled={isEventCancelled} setSelected={setSelected} setAllowBuyTicket={setAllowBuyTicket} currency={currency} isUser={user} seatings={assignedSeatings} ticketsCategory={tickets} selectedSeat={selectedSeat} selectSeatArray={setSelectedSeat} selectedTickets={selectedTickets} />)
              : buySeat === 'seats' && (!showSeatsTab || (showSeatsTab && onDemand)) ? (
                <div className='wrapperBodyEmpty'>
                  <img src={NoEventsInformation} alt='Information Logo' />
                  <div className='informationDescription'>No seats available</div>
                </div>
              )
                : buySeat === 'other' && showOtherTab && filteredTickets.length ? (
                  <>
                    {
                      subpromoters.length > 1
                        ? <div className='wrapperCategory'>
                          <div className='wrapperLeftRight'>
                            <div className='left'>
                              <div className='price'>Promoter</div>
                            </div>
                            <div className='right slc-container'>
                              <div className='ticketsCount'>Choose from the list</div>
                              <Select
                                suffixIcon={<DownOutlined style={{ color: 'red' }} />}
                                value={selectValue}
                                className='right promo-slt'
                                onChange={handleSelect}
                              >
                                {subpromoters.map(subpromoter => {
                                  return (
                                    <Option key={subpromoter.id} value={subpromoter.id}>{subpromoter?.isVenueUser ? `${subpromoter.venueName}` : `${subpromoter.firstName} ${subpromoter.lastName}`}</Option>
                                  )
                                })}
                              </Select>
                            </div>
                          </div>
                          {/* eslint-disable-next-line react/jsx-closing-tag-location */}
                        </div>
                        : ''
                    }
                    {filteredTickets.map((i, index) => {
                      const isEarlyBird = i?.isEarlyBird && checkEarlyBirdActuality(i.earlyBirdDate, timeZone)

                      const currentPrice = i?.isEarlyBird ? i.earlyBirdPrice : i.price
                      const earlyBirdDate = isEarlyBird ? moment.tz(i.earlyBirdDate, timeZone).format('MM.DD.YYYY') : null

                      const selectedTicketsCount = selectedTickets.find(ticket => ticket?.id === i._id)?.count || 0
                      if (!i.isDonate && currentPrice > 0) {
                        return (
                          <div key={i._id} className='wrapperCategory'>
                            <div className='categoryName'>{i.categoryName}</div>
                            <div className='wrapperLeftRight'>
                              <div className='left'>
                                <div className='wrapperEarlyBirdPrice'>
                                  <div className='price'>{getPriceDisplay(index)}</div>
                                  {earlyBirdDate && (
                                    <div className='earlyBirdDate'>"Early Bird Special" until {earlyBirdDate}</div>
                                  )}
                                </div>
                              </div>
                              <div className='right'>
                                {i?.showAvailableTickets && i?.ticketCount.available !== 0 &&
                                  <div className='ticketsCount'>
                                    Available tickets:{' '}
                                    {i?.ticketCount.available - selectedTicketsCount}
                                    <span>/</span>
                                    {i?.quantity}
                                  </div>}
                                <div className='soldoutWrapper'>
                                  {i?.ticketCount.available === 0 && <div className='soldoutBage'>Sold Out</div>}
                                  <Counter
                                    setAllowBuyTicket={setAllowBuyTicket}
                                    min={0}
                                    max={i?.ticketCount.available}
                                    count={selectedTicketsCount}
                                    setCount={num => setSelected(i._id, num, i)}
                                    disabled={isEventCancelled || i?.ticketCount.available === 0}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    })}
                  </>
                ) : buySeat === 'other' && showOtherTab
                  ? (
                    <div className='wrapperBodyEmpty'>
                      <img src={NoEventsInformation} alt='Information Logo' />
                      <div className='informationDescription'>No tickets available</div>
                    </div>
                  ) : buySeat === 'bundles' && bundles.length ? (
                    <>
                      {bundles?.map((bundle, index) => {
                        const selectedBundlesCount = selectedBundles.find(ticket => ticket?.id === bundle?.id)?.count || 0
                        return (
                          <div key={bundle._id} className='wrapperCategory'>
                            <div className='categoryName'>{bundle.bundleName}</div>
                            <div className='wrapperLeftRight'>
                              <div className='left'>
                                <div className='wrapperEarlyBirdPrice'>
                                  <div className='price'>{currencySymbol + bundle.price}</div>
                                </div>
                              </div>
                              <div className='right'>
                                {showAvailableTickets && bundle?.bundleCount.available !== 0 &&
                                  <div className='ticketsCount'>
                                    Available bundles:{' '}
                                    {bundle?.bundleCount.available - selectedBundlesCount}
                                    <span>/</span>
                                    {bundle?.quantity}
                                  </div>}
                                <div className='soldoutWrapper'>
                                  {bundle?.bundleCount.available === 0 && <div className='soldoutBage'>Sold Out</div>}
                                  <Counter
                                    min={0}
                                    max={bundle?.bundleCount.available}
                                    count={selectedBundlesCount}
                                    setCount={num => handleSelectBundles(index, num, bundle)}
                                    disabled={isEventCancelled || bundle?.bundleCount.available === 0}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                    </>
                  ) : (
                    <div className='wrapperBodyEmpty'>
                      <img src={NoEventsInformation} alt='Information Logo' />
                      <div className='informationDescription'>No bundles available</div>
                    </div>
                  )}
            {visible &&
              <ModalBuyTicket
                currencySymbol={currencySymbol}
                img={imageUrl?.thumbnail1}
                visible={visible}
                setVisible={setVisible}
                onChange={onChange}
                totalPriceSeats={totalPriceSeats}
                totalPriceTickets={totalPriceTickets}
                totalPriceBundles={totalPriceBundles}
                orderedBundles={trimArray(selectedBundles)}
                orderedTickets={trimArray(selectedTickets)}
                orderedSeats={selectedSeat}
                donation={donationAmount}
                eventID={id}
                tickets={tickets}
                callback={updateAfterBuy}
              />}
            <ModalRaffleCategory subtitle={`${soldRaffleTicket} charity raffle tickets have been sold`} title={`Would you like to add charity raffle ticket for ${currencySymbol}${raffleCategory?.price}?`} onClose={() => setVisibleModalRaffle(false)} onCancel={onCloseRaffleModal} onSubmit={handleAddRaffleCategory} visible={visibleModalRaffle} />
          </div>
          <div className='sidebar'>
            <div className='title'>Selected tickets</div>
            <Divider />
            {trimArray(selectedTickets).map(ticket => {
              const isEarlyBirdActive = ticket.isEarlyBird && checkEarlyBirdActuality(ticket.earlyBirdDate, timeZone)
              return (
                <div key={ticket.id}>
                  <div className='flex'>
                    <div className='type'>{ticket.categoryName}</div>
                    <div className='flex'>
                      <div className='price'>
                        {`${currencySymbol}${isEarlyBirdActive ? ticket.earlyBirdPrice : ticket.price}`}
                      </div>
                      <img onClick={() => handleRemoveCategory(ticket.id)} className='removeBuySeat' src={RemoveBuySeat} alt='removeBuySeat' />
                    </div>
                  </div>
                  <div className='wrapperQuantityEarlyBird'>
                    <div className='quantity'>Tickets q-ty <span>{ticket.count}</span></div>
                    {isEarlyBirdActive && <span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px', marginRight: '35px' }}>{currencySymbol}{ticket.price}</span>}
                  </div>
                  <Divider />
                </div>
              )
            })}
            {trimArray(selectedBundles).map(bundle => {
              return (
                <div key={bundle.id}>
                  <div className='flex'>
                    <div className='type'>{bundle.bundleName}</div>
                    <div className='flex'>
                      <div className='price'>
                        {`${currencySymbol}${bundle.price}`}
                      </div>
                      <img onClick={() => handleRemoveBundle(bundle.id)} className='removeBuySeat' src={RemoveBuySeat} alt='removeBuySeat' />
                    </div>
                  </div>
                  <div className='wrapperQuantityEarlyBird'>
                    <div className='quantity'>Bundles q-ty <span>{bundle.count}</span></div>
                  </div>
                  <Divider />
                </div>
              )
            })}
            {trimArraySeat(selectedSeat).map((seat, index) => {
              const isEarlyBirdActive = seat.isEarlyBird && checkEarlyBirdActuality(seat.earlyBirdDate, timeZone)
              return (
                <div key={index}>
                  <div className='flex'>
                    <div className='type'>{seat.sectionName}</div>
                    <div className='flex'>
                      <div className='priceEarly'>
                        <div className='price'> {`${currencySymbol}${isEarlyBirdActive ? seat.earlyBirdPrice : seat.price}`}</div>
                        {isEarlyBirdActive && <span style={{ textDecoration: 'line-through', color: '#62677E', fontSize: '16px', fontWeight: '400px' }}>{currencySymbol}{seat.priceWithoutEarlyBird}</span>}
                      </div>
                      <img onClick={() => handleRemoveSeat(seat.seatId, seat.guestReservationToken)} className='removeBuySeat' src={RemoveBuySeat} alt='removeBuySeat' />
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className='quantity'>{seat.sectionType === 'TABLES' ? 'Table' : 'Row'} <span>{seat.rowName}</span></div>
                    <div className='quantity'>Seat <span>{seat.seatName}</span></div>
                  </div>
                  <Divider />
                </div>
              )
            })}
            {isDonationEnableLocal && (
              <div key='donation'>
                <div className='flex'>
                  <div className='price'>Donate</div>
                  <Counter
                    min={0.01}
                    max={1000000}
                    count={donationAmountSumm}
                    setCount={num => setDonateSelected(parseFloat(num))}
                    placeholder={currencySymbol + '0.00'}
                    disabled={isEventCancelled}
                    hideButton
                  />
                </div>
                <div className='quantity'>Input amount</div>
              </div>
            )}
            {isDonationEnableLocal && <Divider />}
            <div className='flex'>
              <span className='type'>TOTAL</span>
              <span className='price'>{`${currencySymbol}${getFormattedNumber(totalPrice + donationAmount)}`}</span>
            </div>
            <CustomButton
              text={totalPrice ? `Get tickets for ${currencySymbol}${getFormattedNumber(totalPrice + donationAmount)}` : 'Get tickets'}
              disabled={isEventCancelled}
              className='btn'
              type='dark'
              onClick={onChange}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default Body
