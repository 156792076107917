import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button } from 'antd'
import { joinVirtualEvent } from 'modules/events/events-actions'
import { truncatedText } from 'utils'
import { InviteActionButtons, OverflowNotes, BarcodeSlider } from 'components'
import arrowSingle from 'images/arrowSingle.svg'
import joinEventIcon from 'images/join-event.svg'
import Attachments from './Attachments'
import TicketDetails from './TicketDetails'
import EventDetails from './EventDetails'
import eventLiveLogo from 'images/eventLive-logo.png'
import eventLiveLogoMobile from 'images/eventLive-logo.svg'
import DropDownIcon from 'images/DropDownIcon.svg'
import email from 'images/email.svg'
import gift from 'images/gift.svg'
import winnerBage from 'images/winnerBage.svg'
import MobileWinnerBage from 'images/mobileWinnerBage.svg'
import c from 'classnames'
import './index.styl'
import GiftForm from './TicketForms/giftForm'
import moment from 'moment'
import SpecialRaffleDetails from './SpecialRaffleDetails'
import SpecialItemDetails from './SpecialItemDetails'

const TicketOrder = ({
  ticketData = {},
  tag = 'Ticket',
  onSelectInvite = () => { },
  onCancel = () => {},
  orderId = undefined,
  updateOrder = () => {},
}) => {
  const [expandedTicket, setExpandedTicket] = useState(false)
  const [currentInd, setCurrentIndex] = useState(1)
  const [withGift, setWithGift] = useState(false)
  const { isInvite = false, eventID } = ticketData
  const isPreviewTicket = tag === 'PreviewTicket'
  const { user } = useSelector(state => state.session)

  const dispatch = useDispatch()

  const getCurrentBarcode = ticketData?.ticketPasses?.length ? ticketData?.ticketPasses[currentInd - 1]?.barcode : null
  const isCurrentBarcodeScanned = ticketData?.ticketPasses?.length && ticketData?.ticketPasses[currentInd - 1]?.isScanned
  const handleExpanded = () => {
    setExpandedTicket(!expandedTicket)
  }
  tag = isInvite ? 'Invite' : tag
  const isSimpleTicket = !ticketData?.simpleTicket

  const barcode = !!ticketData?.ticketPasses?.length && !!getCurrentBarcode && `${process.env.REACT_APP_API_BARCODE}` + getCurrentBarcode
  const onJoinEvent = () => {
    if (ticketData?.ticket?.isVirtualTicket && getCurrentBarcode) {
      dispatch(joinVirtualEvent(getCurrentBarcode))
    }
  }
  // we allow regift private live stream only for promoter
  const isUserPromoter = user?._id === ticketData?.userID && ticketData?.ticketType === 'PRIVATE_STREAM'
  const isStreaming = ticketData.ticketType === 'PUBLIC_STREAM' || ticketData.ticketType === 'PRIVATE_STREAM'
  const hasPrivateStream = ticketData.ticketType === 'PUBLIC_STREAM' || ticketData.ticketType === 'PRIVATE_STREAM'
  const isLiveEvent = !ticketData?.ticket?.isVirtualTicket && !hasPrivateStream
  const joinAllowed = (((tag === 'Ticket' || ticketData?.inviteStatus === 'ACCEPTED') && (ticketData?.ticket?.isVirtualTicket && ticketData?.zoom_url)) ||
    ((tag === 'Ticket' || ticketData?.inviteStatus === 'ACCEPTED') && (isStreaming)))
  const joinBtnContent = (
    <>
      <span>Push to</span>
      <span>Join the Event</span>
      <img src={joinEventIcon} alt=' ' />
    </>
  )
  const ticketDescription = ticketData?.ticket?.description || ticketData?.ticket?.virtualEvent?.description || ticketData?.eventId?.itemDescription

  const isBarCodeVisible = ticketData?.ticketType === 'PRIVATE_STREAM' ? (isUserPromoter && barcode) : (!isInvite && barcode && isLiveEvent)
  const linkTo = isStreaming && getCurrentBarcode ? `/watch?e=${eventID}&b=${getCurrentBarcode}` : ticketData?.zoom_url
  const backgroundColor = ticketData?.backgroundColor || ticketData?.ticketColor
  const bottomContent = {
    Ticket:
  <div className='wrapperAction'>
    {!withGift &&
      <div className={isBarCodeVisible ? 'expandedHandler' : 'bottomDesign'}>
        {isBarCodeVisible && (
          <Button className='expandedButton' onClick={handleExpanded}>
            <div>Would you like to gift the ticket?</div>
            <img className={expandedTicket ? 'rotateImg' : ''} src={DropDownIcon} alt=' ' />
          </Button>
        )}
      </div>}

    {withGift &&
      <>
        <div className='wrapperHeaderTitle'>
          <img onClick={() => setWithGift(false)} src={DropDownIcon} alt=' ' />
          <div className='headerTitle'>Gift Ticket</div>
        </div>
        <div>
          <GiftForm
            ticketData={ticketData} currentBarcode={getCurrentBarcode} isSimpleTicket={isSimpleTicket} orderId={orderId} closeFormGift={() => {
              setWithGift(false)
              handleExpanded()
            }} updateOrder={updateOrder}
          />
        </div>
      </>}
    {expandedTicket && !withGift &&
      <div className='wrapperOptions'>
        <Button onClick={() => setWithGift(true)} className='ticketsButton' type='primary'>
          <div className='buttonItems'>
            <img src={gift} alt='gift' />
            <div className='itemText'>Gift Ticket</div>
          </div>
        </Button>
      </div>}
  </div>,

    Invite: <div className='bottomDesign' />,
    PreviewTicket: <div className='bottomDesign' />,
  }
  const renderNotesMediaQuery = query => (
    <div className={query}>
      {!!ticketDescription && (
        <OverflowNotes additionalInfo={ticketData?.eventId?.itemDescription} notes={ticketDescription} />
      )}
    </div>
  )
  const isNextStream = currentInd < ticketData?.ticketPasses?.length
  const isPrevStream = currentInd > 1
  const mainContent = {
    Ticket: (
      <>
        {isBarCodeVisible && (
          <BarcodeSlider
            onNext={() => setCurrentIndex(currentInd + 1)}
            onPrev={() => setCurrentIndex(currentInd - 1)}
            isNext={currentInd < ticketData?.ticketPasses?.length}
            total={ticketData?.ticketPasses?.length}
            showSlider={!ticketData?.simpleTicket}
            isScanned={isCurrentBarcodeScanned}
            isPrev={currentInd > 1}
            current={currentInd}
            barcode={getCurrentBarcode}
          />
        )}
        {joinAllowed && hasPrivateStream && (
          <div className='logoWrapper'>
            <div>
              <Link to={linkTo} className='joinButton'>{joinBtnContent}</Link>
              {!ticketData?.simpleTicket &&
                <div className='sliderControls'>
                  <Button className='buttonPrev' onClick={() => setCurrentIndex(currentInd - 1)} disabled={!isPrevStream}>
                    <img src={arrowSingle} alt='' className='controlLeft' />
                  </Button>
                  <div className='summaryContainer'>
                    <span>{currentInd}</span>of<span>{ticketData?.ticketPasses?.length}</span>
                  </div>
                  <Button className='buttonNext' onClick={() => setCurrentIndex(currentInd + 1)} disabled={!isNextStream}>
                    <img src={arrowSingle} alt='' className='controlRight' />
                  </Button>
                </div>}
            </div>
            <div className='logoContainer'>
              <img className='eventLogo' src={eventLiveLogo} alt='powered by eventlive' />
              <img className='eventLogoMobile' src={eventLiveLogoMobile} alt=' ' />
            </div>
          </div>
        )}
        {joinAllowed && !isStreaming && (
          <a href={linkTo} className='joinButton' target='_blank' rel='noopener noreferrer' onClick={onJoinEvent}>{joinBtnContent}</a>
        )}
      </>
    ),
    Invite: isInvite && ticketData?.inviteStatus !== 'ACCEPTED' && ticketData?.inviteStatus !== 'REJECTED' && (
      <div className='inviteActions'>
        <InviteActionButtons inviteData={ticketData} callback={onCancel} onSelectInvite={onSelectInvite} />
      </div>
    ),
  }
  const presentedBy = ticketData?.presentedBy
  const renderSubtitle = (
    <div className='eventSubtitle'>
      {!!presentedBy?.length && (<span className='presentedBy'>Presented by <span>{presentedBy}</span></span>)}
      <Link to={`/send-mail?eventID=${eventID}`} className='email'><img src={email} alt=' ' />Email to Promoter</Link>
    </div>)
  const venueTicketProps = isLiveEvent ? ticketData?.ticketPasses?.length && ticketData?.ticketPasses[currentInd - 1] || {} : {}
  const winnerBageNote = ticketData?.ticketPasses && ticketData?.ticketPasses?.length ? ticketData?.ticketPasses[currentInd - 1]?.ramdomDrawNotes : ''
  const winnerDetails = ticketData?.ticketPasses && ticketData?.ticketPasses?.length
    ? `Draw ${ticketData?.ticketPasses[currentInd - 1]?.randomDrawNumber}, ${moment(ticketData?.ticketPasses[currentInd - 1]?.randomDrawDate?.createdAt).format('MMM D, YYYY')}`
    : ''
  const winnerBageMobile = window.innerWidth <= 700
  return (
    <div className='TicketOrder'>
      {ticketData.ticketType === 'RAFFLE' || ticketData?.ticket?.isRaffleCategory ? (
        <div className='wrapperBack'>
          <SpecialRaffleDetails />
        </div>
      ) : ticketData.ticketType === 'ITEM' || ticketData?.ticket?.isItemCategory ? (
        <div className='wrapperBack'>
          <SpecialItemDetails />
        </div>
      ) : (
        <div className='wrapperBack'>
          <div className='mobileContent'>
            <div className='eventTitle'>{ticketData.eventName}</div>
            {renderSubtitle}
          </div>
          <TicketDetails {...ticketData} presentedBy={renderSubtitle} />
        </div>
      )}
      <div className='middleContainer'>
        <div className='decorationWrapper'>
          <div className='darkDecoration' />
          <div className='lightDecoration' />
        </div>
        <div className={c('contentWrapper', { isPreviewTicket })}>
          <div className='middleContent'>
            <div className='leftSide'>
              {ticketData?.ticketPasses && ticketData?.ticketPasses[currentInd - 1]?.randomDrawNumber &&
                <div className='winnerBageWrapper'>
                  {winnerBageMobile
                    ? (
                      <div className='mobileWinnerWrapper'>
                        <img src={MobileWinnerBage} alt='MobileWinnerBage' />
                        <div className='mobileWinnerBageDate'>
                          {winnerDetails}
                        </div>
                        <div className='mobileWinnerBageNote'>{truncatedText(winnerBageNote, 55)}</div>
                      </div>
                    ) : (
                      <>
                        <img src={winnerBage} alt='winnerBage' />
                        <div className='winnerBageTitleWrapper'>
                          <div className='winnerBageTitle'>WINNER!!!</div>
                          <div className='winnerBageNote'>{truncatedText(winnerBageNote, 55)}</div>
                        </div>
                        <div className='winnerBageNoteDateWrapperCenter'>
                          <div className='winnerBageDate'>
                            {winnerDetails}
                          </div>
                        </div>
                      </>)}
                </div>}
              <EventDetails {...ticketData} {...venueTicketProps} />
              {renderNotesMediaQuery('smVisible')}
            </div>
            <div className='rightSide'>
              {mainContent[tag] || null}
            </div>
            {renderNotesMediaQuery('xsVisible')}
          </div>
          {ticketData?.inviteStatus !== 'PENDING' &&
            <Attachments attachments={ticketData?.ticket?.attachments} />}
        </div>
      </div>
      <div className='wrapperStripe' />
      <div style={{ backgroundColor, height: 12 }} />
      {bottomContent[tag] || null}
    </div>
  )
}

export default TicketOrder
