import React, { createContext, useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  // editTicketV2,
  // makeTicketV2,
  // setEventsIsStream,
  eventsSetImages,
} from 'modules/events/events-actions'
import {
  makeMemberPage,
  setAdditionalImages,
  editMemberPage,
} from 'modules/memberships/memberships-actions'
import Header from './components/Header'
import ModalBuySubscribe from './components/ModalBuySubscribe'
// import moment from 'moment'
import { CustomButton } from 'components'
import { useDispatch, useSelector } from 'react-redux'

import { currencyCodes } from 'utils'

import Footer from './components/Footer'
import { Button, Form, notification } from 'antd'
import './index.styl'

export const CreateMemberLyfeContext = createContext(null)
const CreateMemberLyfe = ({ currentPageDetails }) => {
  const [form] = Form.useForm()
  const { imageUrls } = useSelector(state => state.events)
  const { additionalImagesUrls } = useSelector(state => state.memberships)
  // console.log('additionalImagesUrls', additionalImagesUrls)
  const [finished, setFinished] = useState(false)
  const [modalSubscribe, setModalSubscribe] = useState(null)
  const { state = {} } = useLocation()
  const { push, goBack } = useHistory()
  const dispatch = useDispatch()
  const { eventID } = useParams()
  const [activeTab, setActiveTab] = useState('1')

  useEffect(() => {
    if (Object.keys(currentPageDetails?.images || {}).length) {
      dispatch(eventsSetImages(currentPageDetails?.images))
    }
    return () => dispatch(eventsSetImages({}))
  }, [currentPageDetails?.images])
  useEffect(() => {
    if (currentPageDetails?.additionalImages?.length) {
      dispatch(setAdditionalImages(currentPageDetails?.additionalImages))
    }
    return () => dispatch(setAdditionalImages({}))
  }, [currentPageDetails?.additionalImages])

  const handleCancel = () => {
    goBack()
  }

  const handleBack = () => {
    if (eventID) {
      push({ pathname: `/member-office/member-details/member-categories/${eventID}`, state })
    } else {
      push({ pathname: '/member-office/table-members', state })
    }
  }

  const onCloseModal = () => {
    setFinished(false)
    setModalSubscribe(null)
  }

  const initialValues = {
    name: currentPageDetails?.name,
    locations: currentPageDetails?.locations,
    timeZone: currentPageDetails?.timeZone,
    eventUrl: currentPageDetails?.eventUrl,
    mobile: currentPageDetails?.mobile,
    businessPhone: currentPageDetails?.businessPhone,
    promoterEmail: currentPageDetails?.promoterEmail,
    description: currentPageDetails?.description,
    announcements: currentPageDetails?.announcements || [{}],
    announcementsSwitch: !!currentPageDetails?.announcements?.length,
    subscriptions: currentPageDetails?.subscriptions || [{}],
    subscriptionsSwitch: !!currentPageDetails?.subscriptions?.length,
    passes: currentPageDetails?.passes || [{}],
    passesSwitch: !!currentPageDetails?.passes?.length,
    sessions: currentPageDetails?.sessions || [{}],
    sessionsSwitch: !!currentPageDetails?.sessions?.length,
    items: currentPageDetails?.items || [{}],
    itemsSwitch: !!currentPageDetails?.items?.length,
    upcomingEvents: currentPageDetails?.upcomingEvents || [{}],
    upcomingEventsSwitch: !!currentPageDetails?.upcomingEvents?.length,
    monday: currentPageDetails?.workingHours?.monday || [{}],
    mondaySwitch: !!currentPageDetails?.workingHours?.monday?.length,
    tuesday: currentPageDetails?.workingHours?.tuesday || [{}],
    tuesdaySwitch: !!currentPageDetails?.workingHours?.tuesday?.length,
    wednesday: currentPageDetails?.workingHours?.wednesday || [{}],
    wednesdaySwitch: !!currentPageDetails?.workingHours?.wednesday?.length,
    thursday: currentPageDetails?.workingHours?.thursday || [{}],
    thursdaySwitch: !!currentPageDetails?.workingHours?.thursday?.length,
    friday: currentPageDetails?.workingHours?.friday || [{}],
    fridaySwitch: !!currentPageDetails?.workingHours?.friday?.length,
    saturday: currentPageDetails?.workingHours?.saturday || [{}],
    saturdaySwitch: !!currentPageDetails?.workingHours?.saturday?.length,
    sunday: currentPageDetails?.workingHours?.sunday || [{}],
    sundaySwitch: !!currentPageDetails?.workingHours?.sunday?.length,
    workingHours: currentPageDetails?.workingHours || {},
    signupFields: currentPageDetails?.signupFields || [{}],
    signupFieldsSwitch: !!currentPageDetails?.signupFields?.length,
    additionalChats: currentPageDetails?.additionalChats?.filter(i => !i.isPrimary) || [{}],
    chatSwitch: !!currentPageDetails?.additionalChats?.filter(i => !i.isPrimary).length,
    rosterDoB: currentPageDetails?.rosterDoB,
    parentApproval: currentPageDetails?.parentApproval || false,
    chatActive: currentPageDetails?.chatActive !== undefined ? currentPageDetails?.chatActive : true,
    ownerApproval: currentPageDetails?.ownerApproval || false,
    isShowMSHomePage: currentPageDetails?.isShowMSHomePage !== undefined ? currentPageDetails?.isShowMSHomePage : true,
    donationEnabled: currentPageDetails?.donationEnabled || false,
  }
  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [currentPageDetails])

  useEffect(() => {
    if (currentPageDetails) {
      const updatedPasses = (currentPageDetails.passes || []).map(pass => {
        if (pass.duration === 'Yearly' && pass.year) {
          return {
            ...pass,
            duration: pass.year,
          }
        }
        return pass
      })

      form.setFieldsValue({
        ...initialValues,
        passes: updatedPasses,
      })
    }
  }, [currentPageDetails])

  const onSubmitBought = (cardToken, agreement) => {
    return new Promise(resolve => {
      const data = { ...modalSubscribe, cardToken, agreement }

      dispatch(makeMemberPage(data))
        .then(response => {
          if (response) {
            onCloseModal()
            handleBack()
          }
          resolve()
        }).catch(resolve)
    })
  }

  const handleFinish = values => {
    if (finished) return
    const removeEmptyDescriptions = data => {
      return data?.map(item => {
        if (!item.description) {
          const { description, ...rest } = item
          return rest
        }
        return item
      })
    }

    const updatedPasses = values.passes.map(pass => {
      if (pass.duration && typeof pass.duration === 'number') {
        return {
          ...pass,
          duration: 'Yearly',
          year: pass.duration,
        }
      }
      return pass
    })

    const eventData = {
      name: values.name,
      locations: values.locations,
      eventUrl: values.eventUrl,
      mobile: values?.mobile,
      businessPhone: values?.businessPhone,
      promoterEmail: values?.promoterEmail,
      timeZone: values.timeZone,
      description: values.description,
      announcements: removeEmptyDescriptions(values.announcements),
      subscriptions: removeEmptyDescriptions(values.subscriptions),
      passes: removeEmptyDescriptions(updatedPasses),
      workingHours: {
        monday: removeEmptyDescriptions(values?.monday || []),
        tuesday: removeEmptyDescriptions(values?.tuesday || []),
        wednesday: removeEmptyDescriptions(values?.wednesday || []),
        thursday: removeEmptyDescriptions(values?.thursday || []),
        friday: removeEmptyDescriptions(values?.friday || []),
        saturday: removeEmptyDescriptions(values?.saturday || []),
        sunday: removeEmptyDescriptions(values?.sunday || []),
      },
      sessions: removeEmptyDescriptions(values.sessions),
      items: removeEmptyDescriptions(values.items),
      upcomingEvents: removeEmptyDescriptions(values.upcomingEvents),
      signupFields: values.signupFields,
      requestDate: values.rosterDoB,
      parentApproval: values.parentApproval,
      donationEnabled: values.donationEnabled,
      ownerApproval: values.ownerApproval,
      isShowMSHomePage: values.isShowMSHomePage,
      additionalChats: values.additionalChats,
      chatActive: values.chatActive,
    }

    if (!Object.keys(imageUrls).length && !currentPageDetails) {
      return notification.warning({ message: 'Upload image for ticket' })
    } else {
      eventData.images = imageUrls
    }
    if (additionalImagesUrls.length) {
      eventData.additionalImages = additionalImagesUrls
    }

    const fees = {}
    if (values?.TLFee) {
      fees.TLFee = values?.TLFee
    }
    if (values?.paymentProcessorFee) {
      fees.paymentProcessorFee = values?.paymentProcessorFee
    }
    eventData.fees = fees
    const action = eventID ? editMemberPage(eventData, eventID) : makeMemberPage(eventData)
    setFinished(true)

    if (!eventID) {
      setModalSubscribe({ ...eventData })
    } else {
      dispatch(action).then(() => {
        setFinished(false)
        handleBack()
      }).catch(() => setFinished(false))
    }
  }

  const handleFinishFailed = ({ errorFields }) => {
    if (errorFields.length > 0) {
      const tabKeyMapping = {
        announcements: '1',
        monday: '2',
        tuesday: '2',
        wednesday: '2',
        thursday: '2',
        friday: '2',
        saturday: '2',
        sunday: '2',
        subscriptions: '3',
        passes: '3',
        sessions: '3',
        items: '3',
        upcomingEvents: '4',
        signupFields: '5',
        chat: '6',
      }
      const firstErrorField = errorFields[0]
      const tabWithError = firstErrorField.name[0]
      const tabKey = tabKeyMapping[tabWithError]
      if (tabKey) {
        setActiveTab(tabKey)
      }
    }
  }

  return (
    <CreateMemberLyfeContext.Provider
      value={{
        form,
        currentPageDetails,
        setActiveTab,
        activeTab,
      }}
    >
      <Form
        form={form}
        scrollToFirstError
        colon={false}
        layout='vertical'
        validateTrigger='onChange'
        initialValues={initialValues}
        onFinish={handleFinish}
        className='memberLyfe'
        onFinishFailed={handleFinishFailed}
      >
        <div className='memberLyfeWrapper'>
          <Header currentPageDetails={currentPageDetails} form={form} />
          <div className='footerBtnsWrapper'>
            <Footer form={form} currentPageDetails={currentPageDetails} />
            <div className='btnsWrapper'>
              <Button className='cancelBtn' onClick={handleCancel} type='link'>Cancel</Button>
              <CustomButton
                htmlType='submit'
                text={eventID ? 'Update Page' : 'Create Page'}
                className='btnMakePage'
                type='dark'
              />
            </div>
          </div>
        </div>
      </Form>
      <ModalBuySubscribe
        dataTicket={modalSubscribe}
        setVisible={onCloseModal}
        image={imageUrls}
        visible={!!modalSubscribe}
        callback={onSubmitBought}
        currency={currencyCodes.USD.code}
      />
    </CreateMemberLyfeContext.Provider>
  )
}

export default CreateMemberLyfe
